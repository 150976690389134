@use "../abstracts" as *;

/* anchor_link
********************************************** */
.anchor_link {
  position: fixed;
  top: rem($header-hgt);
  right: 0;
  display: flex;
  align-items: flex-start;
  max-width: 90%;
  height: fit-content;
  z-index: z(top);
  transition: var(--transit-default);
  transform: translateX(calc(100% - rem(53)));
  pointer-events: none;
  @include mq(med) {
    top: sprem($header-hgt-sp);
  }
  &.active {
    transform: translateX(0);
    @include mq(sp) {
      & + .overlay {
        pointer-events: auto;
        opacity: 1;
      }
    }
    .anchor_link--btn .open {
      transform: rotate(135deg);
    }
  }
  & + .overlay {
    z-index: z(middle);
  }
}
.anchor_link--btn {
  @include center-flex;
  width: rem(53);
  padding: rem(36) rem(12) rem(40) rem(12);
  gap: rem(8);
  @include write-v;
  font-weight: 700;
  line-height: 160%; /* 28.8px */
  border-radius: rem(16) 0 0 rem(16);
  color: var(--clr-wht);
  background-color: var(--bg-main);
  transition: var(--transit-default);
  pointer-events: auto;
  cursor: pointer;
  box-shadow: 0 0 rem(24) 0 rgba($color: #000, $alpha: .1);
  &:hover {
    background-color: var(--bg-main-hvr);
  }
  .open {
    position: relative;
    display: block;
    @include rect(24);
    transition: var(--transit-default);
    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: rem(12);
      height: 2px;
      border-bottom: 2px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
  }
}
.anchor_link--list_wrap {
  min-height: rem(200);
  max-height: calc(100vh - rem($header-hgt-fx) * 2);
  padding: rem(43) rem(48);
  overflow: auto;
  background-color: var(--bg-wht);
  border-radius: 0 0 0 rem(16);
  box-shadow: 0 0 rem(24) 0 rgba($color: #000, $alpha: .1);
  @include mq(med) {
    max-height: calc(100vh - rem($header-hgt-sp) * 2);
  }
}
.anchor_link--list a {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: perc(5,18,em) 0 perc(5,18,em) perc(30,18,em);
  color: #7A7A81;
  line-height: 160%; /* 28.8px */
  pointer-events: auto;
  @include mq(sp) {
    flex-direction: column;
  }
  &::before {
    content: '';
    position: absolute;
    top: perc(12,18,em);
    left: 0;
    width: perc(14,18,em);
    aspect-ratio: 1/1;
    background-color: var(--bg-main);
    border-radius: 50%;
    transition: var(--transit-default);
  }
  &:hover,
  &:hover .fz14 {
    color: var(--clr-main);
  }
  .fz14 {
    color: #ccc;
    line-height: 160%; /* 22.4px */
    margin-top: perc(2,14,em);
    margin-left: perc(16,14,em);
    transition: var(--transit-default);
  }
  .new {
    color: var(--clr-cta);
    margin-left: .5em;
  }
}

@include mq(sp) {
  .loaded .anchor_link {
    animation: pyoko .6s .4s;
    animation-timing-function: ease-in-out;
  }
}