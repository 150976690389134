@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */
.footer {
  background-color: #333841;
  padding-block: rem(120);
  @include mq(sp) {
    padding-block: sprem(60);
  }
  & > .inner {
    display: flex;
    flex-direction: column;
    gap: rem(56);
    @include mq(sp) {
      gap: sprem(40);
    }
  }
}

/* footer_top
********************************************** */
.footer_top {
  display: flex;
  gap: rem(198);
  @include mq(1640) {
    gap: rem(140);
  }
  @include mq(med) {
    flex-direction: column;
    gap: rem(64);
  }
  @include mq(sp) {
    gap: sprem(40);
  }
}
.footer--logo a {
  filter: brightness(0) invert(.97);
  max-width: rem(381);
  @include mq(sp, min, ps) {
    &:has(img) {
      margin-top: rem(-40);
    }
  }
  @include mq(sp) {
    max-width: sprem(240);
    @include auto-margin;
  }
}
.footer--address {
  color: #CCC;
  line-height: 160%; /* 22.4px */
  letter-spacing: perc(.42,14,em);
  .font-en {
    line-height: 170%;
  }
}

/* ---fnav --- */
.fnav {
  flex: 1;
}
.fnav--menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: rem(48) rem(80);
  height: rem(370);
  @include mq(1640) {
    height: rem(520);
  }
  @include mq(1330) {
    display: block;
    column-count: 2;
    height: auto;
  }
  @include mq(sp) {
    column-count: 1;
    margin-bottom: sprem(-24);
  }
}
.fnav--menu .fit {
  @include mq(1640, min, ps) {
    height: 100%;
  }
}
.fnav--menu > li {
  @include mq(1330) {
    break-inside: avoid;
    margin-bottom: rem(48);
  }
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.fnav--menu a {
  display: block;
  color: #F7F7F7;
  letter-spacing: perc(.48,16,em);
  &:hover {
    opacity: .7;
  }
}
.fnav--menu > li > a {
  font-size: calc-fz(18);
  font-family: var(--font-en, --font-jp);
  color: #7A7A81;
  font-weight: 700;
  line-height: 160%; /* 28.8px */
  letter-spacing: perc(1,18,em);
  margin-bottom: perc(16,18,em);
}

/* ---sns_area --- */
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(40);
  &:hover {
    opacity: 0.7;
  }
}
.sns_area svg {
  @include rect(24);
}

/* footer_md
********************************************** */
.footer_md {
  display: flex;
  align-items: center;
  @include mq(sp, min, ps) {
    justify-content: space-between;
  }
  @include mq(sp) {
    flex-direction: column;
    gap: sprem(32);
  }
}
.footer--youtube {
  display: flex;
  align-items: center;
  gap: rem(22);
  &:hover {
    opacity: .7;
  }
  img {
    @include mq(sp) {
      width: sprem(100) !important;
    }
  }
  .font-en {
    display: flex;
    align-items: center;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: perc(1.25,16,em);
    color: #7A7A81;
    &::after {
      content: '';
      display: block;
      min-width: rem(33);
      aspect-ratio: 1/1;
      background-image: url(../images/common/arrow-out.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}
.footer--cta .btn a {
  @include mq(sp) {
    padding: rem(20) rem(36);
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: rem(32);
  border-top: 1px solid #646B7A;
  @include mq(sp) {
    flex-direction: column;
    gap: sprem(8);
    padding-top: sprem(16);
  }
}
.fnav2 {
  @include mq(sp) {
    width: 100%;
  }
}
.fnav2--menu {
  display: flex;
  flex-wrap: wrap;
  gap: rem(24);
  @include mq(sp) {
    display: block;
  }
}
.fnav2--menu a {
  display: block;
  color: #CCC;
  &:hover {
    opacity: .7;
  }
}
.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.privacy {
  @include mq(sp) {
    text-align: center;
    margin-bottom: 0.5em;
  }
}
.privacy a:hover {
  opacity: 0.7;
}
.copyright {
  gap: 1em;
  font-weight: 700;
  line-height: 160%; /* 22.4px */
  letter-spacing: perc(.75,14,em);
  color: #7A7A81;
  @include mq(sp) {
    gap: .5em;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: .5;
  &:hover {
    opacity: .8;
  }
}
.pbl img {
  @include auto-margin;
}

/* ##############################################################################

    スタンダード用

############################################################################## */

/* footer-simple
*************************************************** */
.footer-simple {
  padding: rem(8) 0;
}
.footer-simple:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

// お問い合わせシンプル「以外」のとき
/* footer-01
  *************************************************** */
.footer-01 {
  .footer--logo {
    margin-bottom: rem(32);
    text-align: center;
  }
  .footer--address {
    margin-bottom: rem(48);
    font-size: calc-fz(15);
    text-align: center;
    @include mq(sp) {
      margin-bottom: rem(14);
    }
  }
  .fnav {
    margin-bottom: rem(40);
    @include mq(sp) {
      margin-bottom: sprem(14);
    }
  }
  .footer--cta {
    margin-bottom: rem(48);
  }
  .footer--cta a {
    margin-inline: auto;
    text-align: center;
  }
  .fnav--menu {
    justify-content: center;
    line-height: 1;
    @include mq(sp, min, ps) {
      gap: 1em 0;
    }
  }
  .fnav--menu > li:not(:last-child) {
    @include mq(sp, min, ps) {
      border-right: 1px solid;
    }
  }
  .fnav--menu a {
    padding: 1em;
    @include mq(sp, min, ps) {
      margin-block: -1em;
    }
    @include mq(sp) {
      margin-inline: -1em;
    }
  }
  .sns_area ul {
    justify-content: center;
  }
  .copyright {
    justify-content: center;
    margin-top: sprem(14);
  }
}

/* footer-02
  *************************************************** */
.footer-02 {
  .inner {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "logo sns_area"
        "fnav copyright";
    }
  }
  .footer--logo {
    @include mq(sp, min, ps) {
      grid-area: logo;
      border-bottom: 1px solid var(--border-main);
    }
    @include mq(sp) {
      text-align: center;
    }
  }
  .fnav {
    font-size: calc-fz(14);
    @include mq(sp, min, ps) {
      grid-area: fnav;
    }
    @include mq(sp) {
      margin-block: sprem(24);
      padding-block: sprem(14);
      border-block: 1px solid var(--border-main);
    }
  }
  .fnav--menu {
    line-height: 1.5;
    @include mq(sp, min, ps) {
      margin-inline: -1em;
    }
  }
  .fnav--menu a {
    @include mq(sp) {
      margin-inline: -0.5em;
    }
  }
  .menu-item-has-children,
  .fnav--menu .sub-menu {
    @include mq(sp, min, ps) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .fnav--menu .sub-menu {
    @include mq(sp) {
      margin-left: 1em;
    }
  }
  .sns_area {
    @include mq(sp, min, ps) {
      grid-area: sns_area;
      border-bottom: 1px solid var(--border-main);
    }
  }
  .sns_area ul {
    justify-content: flex-end;
    @include mq(sp) {
      justify-content: center;
    }
  }
  .copyright {
    @include mq(sp, min, ps) {
      grid-area: copyright;
      justify-content: flex-end;
    }
    @include mq(sp) {
      justify-content: center;
      flex-direction: row;
      margin-top: sprem(14);
    }
  }
  .copyright small {
    font-size: calc-fz(12);
  }
  .fnav,
  .copyright {
    @include mq(sp, min, ps) {
      padding-top: rem(32);
    }
  }
  .footer--logo,
  .sns_area {
    @include mq(sp, min, ps) {
      padding-bottom: rem(32);
    }
  }
  .footer--cta,
  .footer--address {
    font-size: calc-fz(13);
    line-height: 1.5;
    margin-top: rem(24);
    @include mq(sp, min, ps) {
      padding-top: rem(24);
      border-top: 1px solid var(--border-main);
    }
  }
}

/* footer-03
  *************************************************** */
.footer-03 {
  .inner {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "logo logo"
        "fnav cta"
        "copyright sns_area";
    }
  }
  .footer--logo {
    margin-bottom: rem(24);
    @include mq(sp, min, ps) {
      grid-area: logo;
    }
    @include mq(sp) {
      text-align: center;
    }
  }
  .footer--cta {
    display: flex;
    margin-bottom: rem(24);
    @include mq(sp, min, ps) {
      grid-area: cta;
    }
    @include mq(sp) {
      align-items: center;
      justify-content: center;
    }
  }
  .footer--cta .cta_tel a {
    margin-inline: auto;
  }
  .fnav {
    margin-bottom: rem(24);
    @include mq(sp, min, ps) {
      grid-area: fnav;
    }
  }
  .fnav--menu {
    @include mq(sp, min, ps) {
      margin-inline: -1em;
      gap: rem(40);
    }
  }
  .cta {
    @include mq(sp, min, ps) {
      grid-area: cta;
    }
  }
  .copyright {
    @include mq(sp, min, ps) {
      grid-area: copyright;
    }
  }
  .sns_area {
    @include mq(sp, min, ps) {
      grid-area: sns_area;
    }
    @include mq(sp) {
      margin-bottom: sprem(32);
      margin-top: sprem(14);
    }
  }
  .sns_area ul {
    justify-content: flex-end;
    @include mq(sp) {
      justify-content: center;
    }
  }
  .copyright {
    justify-content: flex-start;
  }
  .copyright,
  .sns_area {
    @include mq(sp, min, ps) {
      padding-top: rem(24);
      border-top: 1px solid var(--border-main);
    }
  }
  .footer--address {
    font-size: calc-fz(13);
    line-height: 1.5;
    opacity: 0.5;
    @include mq(sp) {
      margin-bottom: sprem(14);
    }
  }
}

/* footer-04
  *************************************************** */
.footer-04 {
  .inner {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: rem(640) 1fr;
      gap: 0 rem(150);
      grid-template-areas:
        "img logo"
        "img address"
        "img sns_area"
        "img cta"
        "img copyright";
    }
    &::before {
      content: "::before";
      display: block;
      width: 100%;
      background-color: #aaa;
      @include mq(sp, min, ps) {
        grid-area: img;
      }
      @include mq(sp) {
        width: 100vw;
        height: sprem(180);
        margin-inline: calc(50% - 50vw);
        margin-bottom: sprem(28);
      }
    }
  }
  .footer--logo {
    margin-bottom: rem(32);
    @include mq(sp, min, ps) {
      grid-area: logo;
    }
    @include mq(sp) {
      text-align: center;
    }
  }
  .footer--address {
    margin-bottom: rem(16);
    font-size: calc-fz(15);
    @include mq(sp, min, ps) {
      grid-area: address;
    }
  }
  .sns_area {
    margin-bottom: rem(32);
    @include mq(sp, min, ps) {
      grid-area: sns_area;
    }
    @include mq(sp) {
      margin-top: sprem(14);
    }
  }
  .sns_area ul {
    @include mq(sp) {
      justify-content: center;
    }
  }
  .footer--cta {
    margin-bottom: rem(32);
    @include mq(sp, min, ps) {
      grid-area: cta;
    }
  }
  .copyright {
    @include mq(sp, min, ps) {
      grid-area: copyright;
    }
  }
  .fnav {
    grid-column: 1 / -1;
    margin-top: rem(32);
    margin-inline: -1em;
    font-size: calc-fz(14);
    line-height: 1.5;
  }
}
