@use '../abstracts' as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes zoomout {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pyoko {
  0% {
    transform: translateX(calc(100% - rem(53)));
  }
  25% {
    transform: translateX(calc(100% - rem(45)));
  }
  50% {
    transform: translateX(calc(100% - rem(53)));
  }
  75% {
    transform: translateX(calc(100% - rem(45)));
  }
  100% {
    transform: translateX(calc(100% - rem(53)));
  }
}