@use "../abstracts" as *;

/* cta_box
**************************************** */
.cta_box {
  display: flex;
  justify-content: center;
  gap: rem(24);
  @include mq(sp) {
    flex-direction: column;
    align-items: center;
    gap: sprem(10);
  }
}
.cta_box a {
  height: rem(80);
  @include mq(sp, min) {
    width: rem(320);
  }
}
@include mq(sp) {
  .cta_box li {
    width: 100%;
  }
}

/* cta_tel
**************************************** */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: rem(12);
  line-height: 1.4;
  text-decoration: none;
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: calc-fz(20);
}
.cta_tel .num {
  font-size: rem(28);
}

/* cta_chat
**************************************** */
.cta_chat {
  @include mq(sp, min, ps) {
    padding: rem(48);
  }
}

/* cta_contact
**************************************** */
.cta_contact a {
  @include center-flex;
  border-radius: rem(4);
}
