@use "../abstracts" as *;

/* ##############################################################################

  SINGLE

############################################################################## */

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}
div#ez-toc-container {
  padding: rem(24);
  background-color: #fafafa;
}
.ez-toc-title-container {
  margin-bottom: rem(10);
}
div#ez-toc-container p.ez-toc-title {
  color: var(--clr-body);
  font-weight: bold;
  font-size: calc-fz(20);
}
#ez-toc-container.counter-hierarchy ul {
  font-size: calc-fz(17);
}
#ez-toc-container.counter-hierarchy ul li {
  margin-top: rem(8);
}
#ez-toc-container.counter-hierarchy ul ul {
  margin-left: rem(16);
}
#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: rem(16);
  &::before {
    content: "";
    position: absolute;
    top: rem(10);
    left: 0;
    width: rem(8);
    height: 0;
    border-bottom: 1px solid;
  }
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}
#pass input[name="post_password"] {
  padding: rem(5);
  border: solid 1px #aaa;
  outline: none;
}
#pass input[type="submit"] {
  padding: rem(4) rem(6);
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: rem(4);
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
}
#pass input[type="submit"]:hover {
  opacity: 0.7;
}

/* share
**************************************** */
.share--list a {
  @include center-flex;
  height: rem(40);
  padding: 0.5em;
  line-height: 1;
  font-size: calc-fz(12);
  font-weight: bold;
  border: 1px solid #ddd;
}
.share--list li {
  &.x a {
    color: #000;
  }
  &.facebook a {
    color: #2477f2;
  }
  &.line a {
    color: #27c754;
  }
  &.pocket a {
    color: #ef4056;
  }
  &.linkedin a {
    color: #2867b2;
  }
  &.hatena a {
    color: #29a4de;
  }
}
.share--list img {
  margin-right: rem(10);
}
.share--list a:hover {
  opacity: 0.5;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: calc-clamp(12, $wid-var);
  white-space: nowrap;
}
.wp-pagenavi > * {
  @include center-flex;
  border-radius: 9999px;
  &.current {
    background-color: var(--bg-off_wht);
  }
}
.wp-pagenavi .pages {
  width: 100%;
  margin-bottom: 1em;
}
.wp-pagenavi a {
  position: relative;
  padding: 0 1em;
  height: calc-clamp(48, $wid-var);
  background-color: #646b7a;
  color: var(--clr-wht);
  @include mq(sp) {
    height: sprem(36);
  }
  &:hover {
    background-color: #babdc4;
  }
}
.archive .wp-pagenavi > *:not(.pages) {
  width: calc-clamp(48, $wid-var);
  aspect-ratio: 1/1;
  @include mq(sp) {
    width: sprem(36);
  }
}
.wp-pagenavi-single a:not([rel]) {
  width: fit-content;
  min-width: min(rem(200), 100%);
}
.wp-pagenavi-single a[rel="prev"],
.wp-pagenavi-single a[rel="next"] {
  aspect-ratio: 1/1;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5em;
  aspect-ratio: 1/1;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="prev"]::before {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="next"]::before {
  transform: rotate(-135deg);
}
.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: rem(-6);
}
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: rem(-6);
}

/* voice
**************************************** */
.voice--eyecatch img {
  width: auto !important;
  max-height: rem(400);
  @include mq(sp) {
    max-height: sprem(200);
  }
}
.voice--dl {
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: rem(140) auto;
    row-gap: rem(8);
  }
  line-height: 160%;
}
.voice--dl dt {
  display: flex;
  @include mq(sp) {
    align-items: center;
    margin-bottom: 0.5em;
  }
  &::before,
  &::after {
    content: "";
    display: block;
    flex: 1;
    min-width: rem(16);
    height: 2px;
    border-bottom: 2px solid #d9d9d9;
    margin: rem(14) rem(12) 0;
    @include mq(sp) {
      margin: 0;
    }
  }
  &::before {
    @include mq(sp, min, ps) {
      display: none;
    }
    @include mq(sp) {
      margin-right: rem(12);
    }
  }
  &::after {
    @include mq(sp) {
      margin-left: rem(12);
    }
  }
}
.voice--dl dd:not(:last-child) {
  @include mq(sp) {
    margin-bottom: 1em;
  }
}
.voice--list li {
  font-size: rem(14);
  line-height: 160%;
  border-radius: 9999px;
  color: var(--clr-wht);
  background-color: #646b7a;
  padding: 0.25em 1em;
}

/* news
**************************************** */
.single-news .news--article .post--img {
  margin-bottom: rem(40);
  flex-shrink: 0;
  width: 100%;
  margin-right: 0;
  background-color: transparent;
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
