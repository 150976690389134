@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: calc-clamp($fz-pc, 1080);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      font-size: rem($fz-sp);
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: min(spvw($fz-sp), rem($fz-sp));
  }
}
body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  color: var(--clr-body);
  height: 100%;
  word-break: break-word;
  // フォントサイズ変更する場合　例：18pxの例：calc-clamp(18, $wid-var)
  font-size: calc-clamp($fz-pc, $wid-var);
  @include mq(sp, min, ps) {
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: rem(1080);
      }
    }
  }
  @include mq(sp) {
    font-size: sprem(16);
    -webkit-text-size-adjust: none;
  }
}
* {
  letter-spacing: var(--ltr-space-default);
}
ul,
ol { list-style: none; }
small { 
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link);
  text-decoration: none;
  transition: var(--transit-default);
  outline : none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a[href="javascript:void(0);"] {
  pointer-events: none;
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
.arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  @include center-flex;
  transition: var(--transit-default);
  @include rect(56);
  background-color: var(--bg-main);
  border-radius: 50%;
  border: 1px solid var(--clr-main);
  &::before {
    content: '';
    display: block;
    @include rect(18);
    background-image: url(../images/common/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: brightness(0) invert(1);
    transition: var(--transit-default);
  }
}
.arrow-out {
  &::before {
    transform: rotate(-45deg);
  }
}
.arrow-wh {
  background-color: var(--bg-wht);
  border-color: var(--clr-wht);
  &::before {
    filter: none;
  }
}
.scrollTrigger {
  position: relative;
  &:has(.pin, .scroll-opacity) {
    min-height: 200vh;
  }
}
.scrollTrigger--sticky {
  top: 0;
  position: sticky;
  height: 100vh;
}
.modal-sp {
  @include mq(sp, min, ps) {
    .overlay,
    .close,
    .modal--default {
      display: none;
    }
  }
  @include mq(sp) {
    .modal--wrap {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      margin: auto;
      width: 86%;
      z-index: z(top);
      pointer-events: none;
      opacity: 0;
      transition: var(--transit-default);
    }
    .modal--content {
      max-height: calc(100svh - (rem($header-hgt-sp) * 2) - rem(80));
      border-radius: rem(16);
      background-color: var(--bg-wht);
      box-shadow: 0 0 rem(24) 0 rgba($color: #000, $alpha: .1);
      overflow: auto;
    }
    &.active {
      .modal--wrap,
      .overlay,
      .close {
        pointer-events: auto;
        opacity: 1;
      }
    }
  }
}
.overlay,
.close {
  z-index: z(top);
  pointer-events: none;
  opacity: 0;
  transition: var(--transit-default);
}
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba($color: #333841, $alpha: .8);
}
.close {
  position: absolute;
  top: rem(-40);
  right: 0;
  @include center-flex;
  @include rect(40);
  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    width: rem(32);
    height: 1px;
    border-bottom: 1px solid #F7F7F7;
  }
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
}
.modal--default {
  display: flex;
  align-items: center;
  color: #9599A3;
  line-height: 160%;
  padding: .5em 1em;
  border: 1px solid #DFDFE0;
  border-radius: 9999px;
  &::after {
    content: '';
    display: block;
    @include rect(18);
    min-width: rem(18);
    background-image: url(../images/common/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: rotate(90deg);
    margin-left: auto;
  }
}
.home {
  .load_item {
    opacity: 0;
    transition: all .8s ease-out;
    transition-delay: .4s;
  }
  &.loaded .load_item {
    opacity: 1;
  }
}