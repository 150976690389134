@use "../abstracts" as *;

/* hero
********************************************** */
.hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
  @include mq(sp) {
    height: 100svh;
  }
  & > .inner {
    height: 100%;
    padding-block: rem(64);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    pointer-events: none;
    @include mq(sp, min, ps) {
      width: calc(100% - rem(128));
      max-width: calc(100% - rem(128));
    }
  }
}
.hero_slides {
  inset: 0;
  margin: auto;
  height: 130%;
  z-index: -1;
}
.hero_slide {
  overflow: hidden;
  .slick-list {
    position: relative;
    overflow: hidden;
  }
  .slick-dots {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(72);
    display: flex;
    flex-direction: column;
    gap: rem(16);
    @include mq(sp) {
      display: none !important;
    }
  }
  .slick-dots li {
    @include rect(6);
    border-radius: 50%;
    background-color: rgba($color: $clr-wht, $alpha: 0.5);
    transition: var(--transit-default);
    cursor: pointer;
    &.slick-active {
      background-color: var(--clr-wht);
    }
  }
  .slide-animation {
    animation: zoomout 8s 0s forwards;
  }
}
.hero_slide-sp {
  display: none !important;
}
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero--catch {
  font-size: rem(152);
  font-weight: 200;
  line-height: 86%; /* 130.72px */
  letter-spacing: perc(-1.52, 152, em);
  mix-blend-mode: difference;
  @include mq(sp) {
    font-size: sprem(56);
  }
  &:not(:last-child) {
    margin-bottom: rem(40);
  }
}
.safari .hero--catch {
  transform: translate3d(0, 0, 0);
}
.hero--ttl {
  font-weight: 500;
  line-height: 170%; /* 54.4px */
  letter-spacing: perc(0.96, 32, em);
  &:not(:last-child) {
    margin-bottom: perc(8, 32, em);
  }
}
.hero--lead {
  line-height: 160%; /* 28.8px */
  letter-spacing: perc(0.54, 18, em);
}
.hero--scroll {
  position: absolute;
  right: 0;
  @include write-v;
  line-height: 160%; /* 22.4px */
  letter-spacing: perc(0.75, 14, em);
}

@include mq(sp) {
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
}

/* home_about
********************************************** */
.home_about {
  @include mq(sp) {
  }
  .ttl-01-sub .fz14 {
    margin-left: rem(16);
  }
  .mce-content-body {
    line-height: 220%; /* 39.6px */
  }
}
.home_about--bnrs {
  max-width: rem(1226);
}
.home_about--bnr {
  &:nth-child(odd) {
    @include mq(sp, min, ps) {
      border-right: 1px dotted #dfdfe0;
      padding-right: rem(80);
    }
    @include mq(sp) {
      border-bottom: 1px dotted #dfdfe0;
      padding-bottom: sprem(32);
    }
  }
  &:nth-child(even) {
    @include mq(sp, min, ps) {
      padding-left: rem(80);
    }
    @include mq(sp) {
      padding-top: sprem(32);
    }
  }
  .ttl-01-sub {
    font-size: calc-fz(14);
    margin-top: 0;
    margin-bottom: perc(11, 14, em) !important;
    padding-left: perc(18, 14, em);
    &::before {
      top: perc(4, 14, em);
      width: perc(10, 14, em);
    }
  }
}
.home_about--ttl {
  letter-spacing: perc(0.54, 18, em);
}

/* home_services
********************************************** */
.home_services {
  overflow: hidden;
  .ttl-03 {
    line-height: 170%;
    letter-spacing: perc(0.72, 24, em) !important;
  }
  .txt-attention {
    opacity: 1;
    line-height: 170%; /* 23.8px */
    letter-spacing: perc(0.42, 14, em);
  }
  .btn a {
    width: 100%;
  }
}
.home_services--ttl {
  line-height: 170%; /* 54.4px */
  letter-spacing: perc(0.96, 32, em);
}
.home_cycle {
  position: relative;
  @include mq(sp) {
    padding-top: sprem(40);
  }
}
.deco-en {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #f7f7f7;
  font-weight: 200;
  font-size: rem(152);
  line-height: 86%; /* 130.72px */
  letter-spacing: perc(-1.52, 152, em);
  white-space: nowrap;
  z-index: -1;
  @include mq(sp) {
    font-size: sprem(60);
  }
}
.home_cycle--deco {
  top: rem(206);
  @include mq(sp) {
    top: 0;
  }
}
.home_cycle--en {
  position: absolute;
  color: #7a7a81;
  font-weight: 700;
  line-height: 160%; /* 28.8px */
  letter-spacing: perc(1, 18, em);
}
.home_cycle--en01 {
  top: rem(104);
  left: 0;
}
.home_cycle--en02 {
  top: rem(304);
  right: 0;
}
.home_cycle--label {
  @include mq(sp, min, ps) {
    transform: translateY(perc(20, 32, em));
    margin-bottom: perc(-54, 32, em);
  }
  @include mq(sp) {
    margin-bottom: sprem(24);
    @include auto-margin;
  }
}
.home_cycle--bg {
  @include mq(sp) {
    width: 108% !important;
    max-width: 108%;
    margin-bottom: sprem(40);
    margin-left: -8%;
  }
}
.home_cycle--list {
  position: relative;
  @include mq(sp, min, ps) {
    margin-top: rem(-467);
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: rem(40);
      background-image: url(../images/services-bg.png);
      background-repeat: repeat-x;
      background-size: auto rem(40);
      z-index: -1;
    }
  }
}
.home_cycle--list > li,
.home_cycle--txtarea {
  display: flex;
  flex-direction: column;
}
.home_cycle--list > li {
  box-shadow: 0 0 rem(24) 0 rgba($color: #000, $alpha: 0.1);
  backdrop-filter: blur(rem(4));
  border-radius: rem(16);
  overflow: hidden;
  &:nth-child(1) .home_cycle--ttl {
    background-color: #a9c25c;
  }
  &:nth-child(2) .home_cycle--ttl {
    background-color: #70b187;
  }
  &:nth-child(3) .home_cycle--ttl {
    background-color: #4da497;
  }
  &:nth-child(4) .home_cycle--ttl {
    background-color: #448eab;
  }
  &:nth-child(5) .home_cycle--ttl {
    background-color: #4e74a1;
  }
}
.home_cycle--ttl {
  line-height: 170%; /* 40.8px */
  letter-spacing: perc(0.72, 24, em);
  padding: perc(16, 18, em);
  @include mq(sp) {
    padding-left: sprem(24);
    padding-right: sprem(24);
  }
  .font-en {
    letter-spacing: perc(0.18, 24, em);
    font-weight: 500;
    margin-right: perc(12, 24, em);
  }
}
.home_cycle--txtarea {
  flex: 1;
  background-color: var(--bg-wht);
  padding: rem(16);
  @include mq(sp) {
    padding: sprem(24);
  }
  ul {
    flex: 1;
    margin-bottom: rem(18);
  }
  li {
    letter-spacing: perc(0.48, 16, em);
    list-style: disc !important;
    margin-left: 1.5em !important;
    &::before {
      content: none !important;
    }
  }
  .btn {
    margin-top: auto;
  }
  .bold {
    font-family: var(--font-jp);
  }
}
.home_services--list {
  max-width: rem(1344);
  @include auto-margin;
}
.home_services--list li {
  padding: rem(48);
  background-color: var(--bg-off_wht);
  border-radius: rem(16);
  @include mq(sp, min, ps) {
    display: flex;
    flex-direction: column;
    .btn {
      margin-top: auto;
    }
  }
  @include mq(sp) {
    padding: sprem(24);
    .ttl-label {
      @include auto-margin;
    }
  }
  p {
    letter-spacing: perc(0.48, 16, em);
  }
}
.home_services--list_ttl {
  line-height: 170%; /* 54.4px */
}

/* home_clients
********************************************** */
.home_clients--list {
  @include mq(sp, min, ps) {
    row-gap: rem(20);
  }
}

/* home_voice
********************************************** */
.home_voice {
  position: relative;
  overflow: hidden;
}
.home_voice--deco {
  bottom: rem(110);
}

/* home_cta
********************************************** */
.home_cta {
  position: relative;
  .section_pdg {
    padding-bottom: rem(90);
    @include mq(sp) {
      padding-bottom: sprem(45);
    }
  }
  .btn a {
    @include mq(sp, min, ps) {
      min-width: min(rem(256), 100%);
    }
  }
}
.home_cta--bg,
.home_cta--filter {
  position: absolute;
  inset: 0;
  z-index: -1;
}
.home_cta--filter {
  background-color: rgba($color: #000, $alpha: 0.5);
}
.home_cta--ttl {
  color: var(--clr-wht) !important;
  @include mq(sp, min, ps) {
    font-size: rem(152) !important;
    font-weight: 200 !important;
    line-height: 86% !important; /* 130.72px */
    letter-spacing: perc(-1.52, 152, em);
  }
}
.home_cta--txt {
  line-height: 170%; /* 54.4px */
  letter-spacing: perc(0.96, 32, em);
  margin-bottom: rem(192);
}

/* home_bnr
********************************************** */
.home_bnr {
  background-color: #f2f2f2;
}
.home_bnr--list {
  border-bottom: 1px solid #babdc4;
  padding-bottom: rem(32);
}
.home_bnr--list a {
  display: block;
  &:hover {
    opacity: 0.7;
  }
}
.home_bnr--dl {
  width: fit-content;
  @include auto-margin;
  align-items: center;
  @include mq(sp, min, ps) {
    padding-block: rem(14);
  }
  @include mq(sp) {
    padding-top: sprem(28);
  }
}
.home_bnr--ttl {
  line-height: 160%; /* 28.8px */
  letter-spacing: perc(0.54, 18, em);
}
.home_bnr--txt {
  line-height: 170%; /* 23.8px */
  letter-spacing: perc(0.42, 14, em);
  color: #7a7a81;
}

/* home_news
********************************************** */
.home_news .lps_sec--wrap {
  grid-template-columns: auto rem(1200);
  .flex-col3 > * {
    width: 32%;
  }
}
.home_news .posts-news .post {
  flex-direction: column-reverse !important;
}
