@use '../abstracts' as *;

/* sitemap
*************************************************** */
.sitemap--menu {
  @include mq(sp, min, ps) {
    column-count: 2;
    gap: 5%;
  }
  .arrow {
    display: flex;
    background-color: transparent;
    border: 0;
    &::before {
      filter: none;
    }
  }
}
.sitemap--menu > li {
  break-inside: avoid;
}
.sitemap--menu > li > a {
  font-size: calc-fz(18);
  border-bottom: 1px solid #D3D3D3;
  padding-top: rem(10);
  padding-bottom: rem(10);
  padding-right: rem(56);
  margin-bottom: rem(10);
}
.sitemap--menu a,
.sitemap--menu span {
  display: block;
}
.sitemap--menu a {
  position: relative;
  &:hover {
    color: var(--clr-main);
  }
}
.sitemap_sub_nav--blc {
  padding-left: rem(16);
  &:not(:last-child) {
    margin-bottom: rem(16);
  }
}
.sitemap--tax {
  border-bottom: 1px solid #eee;
  padding-top: rem(8);
  padding-bottom: rem(8);
  margin-bottom: rem(8);
}
.sitemap_sub_nav a {
  padding-top: rem(4);
  padding-bottom: rem(4);
  padding-left: rem(30);
  &::before {
    content: '';
    position: absolute;
    top: 1em;
    left: 0;
    width: rem(20);
    height: 2px;
    border-bottom: 2px solid #D9D9D9;
  }
}