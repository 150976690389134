@use "../abstracts" as *;

/* --- section_pdg --- */
.section_pdg {
  padding-block: rem(164);
  @include mq(sp) {
    padding-block: sprem(88);
  }
}
.section_pdg-sm {
  padding-block: rem(96);
  @include mq(sp) {
    padding-block: sprem(48);
  }
}
