@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: rem(960);
  @include auto-margin;
  .flow_item {
    position: relative;
    padding-left: rem(96);
    @include mq(sp) {
      padding-left: sprem(56);
    }
    &:not(:last-child) {
      padding-bottom: rem(64);
      @include mq(sp) {
        padding-bottom: sprem(32);
      }
      &::before {
        content: '';
        position: absolute;
        left: rem(32);
        width: 1px;
        border-right: 1px solid var(--clr-main);
        @include mq(sp) {
          left: sprem(20);
        }
      }
    }
    &.txt-wh:not(:last-child)::before {
      border-color: var(--clr-wht);
    }
  }
  .ttl-03 {
    color: var(--clr-main);
    @include mq(sp) {
      font-size: sprem(20);
    }
    &::before {
      position: absolute;
      border-radius: 50%;
    }
  }
  .txt-wh .ttl-03 {
    color: var(--clr-wht);
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32,768,'%');
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300,768,'%');
    }
    @include mq(sp) {
      &:not(:last-child) {
        margin-bottom: sprem(24);
      }
    }
  }
  .mce-content-body {
    margin-bottom: 0 !important;
    @include mq(sp, min, ps) {
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) {
  .flow_item:not(:last-child)::before {
    top: perc(40,16,em);
    bottom: perc(4,16,em);
    @include mq(sp) {
      top: perc(34,16,em);
    }
  }
  .ttl-03::before {
    content: '';
    top: perc(6,24,em);
    left: rem(-76);
    width: perc(24,24,em);
    aspect-ratio: 1/1;
    border: perc(4,24,em) solid var(--clr-main);
    @include mq(sp) {
      left: sprem(-46);
    }
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num {
  .flow_item {
    counter-increment: number;
    &:not(:last-child)::before {
      top: rem(64);
      bottom: rem(20);
      @include mq(sp) {
        top: sprem(46);
        bottom: sprem(16);
      }
    }
  }
  .ttl-03 {
    @include mq(sp, min, ps) {
      font-size: rem(32);
    }
    &::before {
      content: counter(number);
      top: rem(-10);
      left: rem(-96);
      @include center-flex;
      @include rect(64);
      text-align: center;
      color: var(--clr-wht);
      background-color: var(--clr-main);
      font-family: var(--font-en);
      font-size: calc-fz(24);
      letter-spacing: 0;
      @include mq(sp) {
        top: sprem(-4);
        left: sprem(-56);
        @include sprect(40);
      }
    }
  }
  .txt-wh .ttl-03::after {
    color: var(--clr-main);
    background-color: var(--bg-wht);
  }
}