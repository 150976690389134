@use "../abstracts" as *;

/* bg
********************************************** */
.bg-main {
  background-color: var(--bg-main);
}
.bg-sub {
  background-color: var(--bg-sub);
}
.bg-wh {
  background-color: var(--bg-wht);
}
.bg-off_wh {
  background-color: var(--bg-off_wht);
}
