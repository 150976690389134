@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(16);
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
}
.btn a {
  position: relative;
  z-index: 1;
  @include center-flex;
  padding: rem(20) rem(40);
  border-radius: 9999px;
  width: fit-content;
  min-width: min(rem(200), 100%);
  color: var(--clr-wht);
  background-color: var(--bg-main);
  line-height: 1;
  text-decoration: none;
  font-family: var(--font-en-b);
  font-weight: 700;
  @include mq(sp) {
    min-width: 100%;
    padding: sprem(20) sprem(40);
  }
  &:hover {
    background-color: var(--bg-main-hvr);
  }
  &:not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::after {
    content: '';
    display: block;
    @include rect(18);
    margin-left: rem(16);
    margin-right: rem(-16);
    background-image: url(../images/common/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: brightness(0) invert(1);
    transition: var(--transit-default);
  }
  &[href^="#"]:not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::after {
    transform: rotate(90deg);
  }
  &:not([href^="/"]):not([href*="#anchor-"])::after {
    transform: rotate(-45deg);
  }
  svg {
    margin-left: rem(16);
    margin-right: rem(-16);
    fill: var(--clr-wht);
  }
}
.btn-ctr a {
  @include auto-margin;
}
.btn-rgt a {
  @include mq(sp, min, ps) {
    margin-left: auto;
  }
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-main);
  background-color: var(--bg-wht);
  &:hover {
    background-color: #DFDFE0;
  }
  &::after {
    filter: none !important;
  }
  svg {
    fill: var(--clr-main);
  }
}

/* --- btn-bk --- */
.btn-bk a {
  background-color: #646B7A;
  &:hover {
    background-color: #BABDC4;
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--bg-cta);
  &:hover {
    background-color: var(--bg-cta-hvr);
  }
}

/* --- btn-more --- */
.btn-more.pc-none {
  @include mq(sp) {
    margin-top: sprem(32);
  }
}
.btn-more a {
  position: relative;
  display: block;
  font-family: var(--font-en);
  font-weight: 700;
  font-size: rem(16);
  letter-spacing: perc(1.25,16,em);
  padding-block: rem(16);
  padding-right: rem(80);
  width: fit-content;
  @include mq(sp) {
    @include auto-margin;
  }
  &:hover {
    .arrow {
      border-color: #4880FF;
      background-color: transparent;
      &::before {
        filter: none;
      }
      svg {
        fill: #4880FF;
      }
    }
    .arrow-wh {
      border-color: var(--clr-main);
      background-color: var(--bg-main);
      &::before {
        filter: brightness(0) invert(1);
      }
      svg {
        fill: var(--clr-wht);
      }
    }
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-main);
  }
  &::after {
    content: '';
    @include rect(8);
    color: var(--clr-main);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280),100%);
    }
  }
  a {
    min-width: rem(200);
    @include mq(sp) {
      min-width: 100%;
    }
  }
}
[class*="flex-col"] > .btn a {
  min-width: 100%;
}
.flex:not(.flex-j-start) > .btn {
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
}