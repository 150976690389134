@use '../abstracts' as *;

/* section-contact
********************************************** */
.section-contact,
.mce-content-body {
  .cta_tel {
    width: 100%;
    max-width: calc-clamp(360,$wid-var);
    @include auto-margin;
  }
  .cta_tel a {
    width: 100%;
    padding: rem(32) rem(40);
    @include mq(sp) {
      display: block;
      padding: sprem(16);
    }
  }
}