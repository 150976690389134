@use "../abstracts" as *;

/* lps_parts--gallery
********************************************** */
.lps_parts--gallery {
  position: relative;
  padding-block: rem(64);
  overflow: hidden;
  z-index: 1;
  @include mq(sp, min, ps) {
    aspect-ratio: 1920/800;
  }
  @include mq(sp) {
    padding-block: sprem(40);
    &:not(.only-img) {
      width: 100%;
      height: 83svh;
    }
    &.only-img {
      aspect-ratio: 4/3;
    }
  }
  &:not(:first-child) {
    margin-top: rem(164);
    @include mq(sp) {
      margin-top: sprem(88);
    }
  }
  &:not(:last-child) {
    margin-bottom: rem(164);
    @include mq(sp) {
      margin-bottom: sprem(88);
    }
  }
  &:first-child,
  & + .lps_parts--gallery {
    margin-top: rem(-164);
    @include mq(sp) {
      margin-top: sprem(-88);
    }
  }
  &:last-child {
    margin-bottom: rem(-164);
    @include mq(sp) {
      margin-bottom: sprem(-88);
    }
  }
  & > .inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}
.anchor + .lps_parts--gallery {
  margin-top: rem(-164);
  @include mq(sp) {
    margin-top: sprem(-88);
  }
}
.gallery--img {
  position: absolute;
  inset: 0;
  margin: auto;
  height: 130vh;
  z-index: -1;
  filter: brightness(.85);
  @include mq(sp) {
    height: 130svh;
  }
}
.gallery--catch {
  font-size: rem(130);
  font-weight: 200;
  line-height: 86%; /* 130.72px */
  mix-blend-mode: exclusion;
  letter-spacing: perc(-1.52,152,em);
  @include mq(sp) {
    font-size: sprem(56);
    font-weight: 300;
  }
}