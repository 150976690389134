@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.icon-new_tab {
  @include rect(14);
}
.icon-pdf {
  @include rect(27,12);
}
.lps_sec {
  position: relative;
  &:nth-child(odd) {
    .bg-wh {
      background-color: var(--bg-off_wht);
    }
  }
  &:nth-child(even) {
    background-color: var(--bg-off_wht);
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_sec--wrap .lps_parts--column .btn a {
  margin: inherit;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.lps_sec--wrap {
  @include mq(med, min, ps) {
    position: relative;
    width: 90%;
    max-width: rem(1600);
    @include auto-margin;
    display: grid;
    grid-template-columns: auto rem(920);
    gap: rem(80);
    align-items: flex-start;
    .inner {
      width: 100%;
      max-width: 100%;
    }
    & > *:first-child {
      position: sticky;
      top: rem(164);
      margin-bottom: 0 !important;
    }
    & > *:only-child {
      grid-column: 1/3;
    }
  }
  @include mq(med) {
    .lps_sec--sticky:not(:last-child) {
      margin-bottom: sprem(52);
    }
  }
  &:not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}