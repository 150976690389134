@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  white-space: nowrap;
  width: 100%;
  @include mq(sp, min, ps) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include mq(sp) {
    overflow: auto;
  }
}
.breadcrumbs a {
  color: #7A7A81;
  &:hover {
    color: var(--clr-main);
  }
}
.breadcrumbs span span:not(:last-child)::after {
  content: '';
  display: inline-block;
  @include rect(6);
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  transform: rotate(45deg);
  margin: .15em .5em .15em .75em;
}
.breadcrumb_last {
  color: var(--clr-main);
}