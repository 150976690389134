@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 390;
$wid-var: 1700; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 118;
$header-hgt-sp: 86;
$header-hgt-fx: 80;

// 色
$clr-main: #2F2CE4;
$bg-main: #2F2CE4;
$clr-wht: #fff;

// CSS変数
:root {
  // typography
  --ltr-space-default: .08em;
  --line-height-default: 200%;
  --line-height-hdr: 170%;
  --line-height-none: 1;

  // font-families
  --font-primary: "あおとゴシック R", "Aoto Gothic Regular";
  --font-secondary: "あおとゴシック L", "Aoto Gothic Light";
  --font-jp: "あおとゴシック DB", "Aoto Gothic DemiBold";
  --font-en: 'Raleway', "あおとゴシック R", "Aoto Gothic Regular", sans-serif;
  --font-en-b: 'Raleway', "あおとゴシック DB", "Aoto Gothic DemiBold", sans-serif;

  // text colors
  --clr-main: #2F2CE4;
  --clr-sub: #4880FF;
  --clr-body: #404044;
  --clr-link: #404044;
  --clr-wht: #fff;
  --clr-blk: #000;
  --clr-cta: #e17a40;

  // background colors
  --bg-main: #2F2CE4;
  --bg-main-hvr: #4880FF;
  --bg-sub: #f8f8f8;
  --bg-wht: #fff;
  --bg-off_wht: #F7F7F4;
  --bg-blk: #000;
  --bg-cta: #e17a40;
  --bg-cta-hvr: #dd6b2a;

  // border colors
  --border-main: #404040;

  // animation
  --transit-default: all 0.4s ease-out;

  // formy settings
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  "blue": (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  "red": (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  "green": (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  ),
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
