@use "../abstracts" as *;

/* --- blc:not(:last-child) --- */
.blc:not(:last-child) {
  margin-bottom: rem(80);
  @include mq(sp) {
    margin-bottom: sprem(40);
  }
}
.blc-sm:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.blc-xs:not(:last-child) {
  margin-bottom: rem(16);
  @include mq(sp) {
    margin-bottom: sprem(8);
  }
}
.blc-bd:not(:last-child) {
  padding-bottom: rem(80);
  margin-bottom: rem(80);
  border-bottom: 1px solid #dfdfe0;
  @include mq(sp) {
    padding-bottom: sprem(40);
    margin-bottom: sprem(40);
  }
}
