@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: flex;
  flex-direction: row-reverse;
  gap: rem(80);
  @include mq(med) {
    flex-direction: column;
    gap: sprem(40);
  }
}

/* main_column
**************************************** */
.main_column {
  @include mq(med, min, ps) {
    flex: 1;
  }
}
.main--list {
  @include mq(sp, min, ps) {
    display: flex;
    flex-wrap: wrap;
  }
}
.main--list li {
  @include mq(sp, min, ps) {
    display: flex;
    align-items: center;
    &:not(:last-child)::after {
      content: "";
      display: block;
      min-width: 2px;
      height: 1em;
      border-right: 2px solid #dfdfe0;
    }
  }
  @include mq(sp) {
    &:first-child a {
      padding-top: 1em;
    }
    &:last-child a {
      padding-bottom: 1em;
    }
  }
}
.main--list .current-cat a,
.main--list a:hover {
  @include mq(sp, min, ps) {
    filter: brightness(0.8) contrast(3);
  }
  @include mq(sp) {
    background-color: var(--bg-off_wht);
  }
}
.main--list a {
  display: block;
  color: #9599a3;
  padding: 0.5em 1.5em;
  line-height: 160%;
}

/* --- post --- */
.post {
  position: relative;
}
.post--img {
  background-color: var(--bg-off_wht);
}
.post--img .img-contain {
  max-width: 80%;
}
.post--date {
  color: #7a7a81;
}
.cat_list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: rem(4);
  overflow: hidden;
  z-index: 1;
}
.post--info[class*="flex"] .cat_list {
  margin-left: auto;
}
.cat_list a {
  font-size: calc-fz(13);
  background-color: var(--bg-main);
  color: var(--clr-wht);
  padding-inline: 1em;
  &:hover {
    background-color: var(--bg-main-hvr);
  }
}
.post--ttl:not(:last-child) {
  margin-bottom: 0.5em;
}
.post--link {
  &:not([href="javascript:void(0);"]):hover {
    color: var(--clr-main);
  }
  &:hover svg {
    fill: var(--clr-main);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    pointer-events: auto;
  }
}
.post--list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: perc(26, 14, em);
  pointer-events: none;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: perc(8, 14, em);
    left: 0;
    width: 1em;
    aspect-ratio: 1/1;
    background-color: var(--bg-main);
    border-radius: 50%;
  }
}
.post--list * {
  color: #7a7a81;
  letter-spacing: perc(0.42, 14, em);
}
.post--list li:not(:last-child)::after {
  content: ", ";
  display: inline;
}
.post--list a {
  pointer-events: auto;
  &:hover {
    color: var(--clr-main);
  }
}
.post--more {
  @include center-flex;
  width: fit-content;
  margin-left: auto;
  margin-top: 0.5em;
  &::after {
    content: "";
    display: block;
    @include rect(16);
    margin-left: rem(12);
    background-image: url(../images/common/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: var(--transit-default);
  }
}
.post:has(.post--link:not([href="javascript:void(0);"]):hover) {
  .post--more::after {
    transform: translateX(rem(4));
  }
}

/* side_column
**************************************** */
.side_column {
  @include mq(med, min, ps) {
    width: rem(600);
  }
}
.side_section {
  @include mq(med, min, ps) {
    max-width: rem(300);
  }
  &:not(:last-child) {
    margin-bottom: rem(64);
  }
}
.side--ttl {
  letter-spacing: perc(1, 18, em);
  color: #ccc;
}

.side_section .posts-news .post {
  flex-direction: column !important;
  .post--txtarea {
    flex-direction: column-reverse;
    width: 100%;
  }
  .post--info {
    padding-inline: 1.5rem;
    padding-bottom: 1rem;
    margin-top: 0.5rem;
  }
  .post--ttl {
    position: initial;
    padding-inline: 1.5rem;
    padding-top: 0;
  }
  .post--img {
    height: rem(200);
    @include mq(sp) {
      height: sprem(160);
    }
  }
}

/* --- list --- */
.side--list .current-cat a,
.side--list a:hover {
  filter: brightness(0.8) contrast(3);
}
.side--list a {
  position: relative;
  color: #9599a3;
  display: block;
  padding-left: perc(48, 18, em);
  line-height: 160%; /* 28.8px */
  letter-spacing: perc(0.54, 18, em);
  padding-block: perc(6, 18, em);
  &::before {
    content: "";
    position: absolute;
    top: perc(16, 18, em);
    left: 0;
    width: perc(32, 18, em);
    height: perc(4, 18, em);
    border-bottom: perc(4, 18, em) solid;
  }
}

/* --- post --- */
.posts-side {
  .post {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: rem(24);
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  .post--img {
    width: 30%;
  }
  .post--txtarea {
    flex: 1;
  }
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: var(--line-height-default);
  padding-block: rem(4);
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
  &:hover {
    color: var(--clr-main);
    transition: var(--transit-default);
  }
}
.archive_month {
  display: none;
  padding-left: 1.5em;
}
.archive_month a {
  display: block;
  padding-block: rem(4);
  padding-left: perc(32, 18, em);
  &:hover {
    color: var(--clr-main);
  }
  &::before {
    width: perc(16, 18, em);
    height: 1px;
    border-width: 1px;
  }
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
  &:not(:last-child) {
    margin-right: rem(24);
  }
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  &:hover {
    background-color: #eee;
  }
}
.archive-pulldown .archive_list--label {
  margin-right: rem(8);
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary);
  font-size: calc-fz(16);
  line-height: var(--line-height-default);
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0.4em;
    right: 0.8em;
    margin: auto;
    width: 0.6em;
    height: 0.6em;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    transition: var(--transit-default);
  }
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: var(--transit-default);
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* column
**************************************** */
.posts-column {
  .post {
    display: flex;
    flex-wrap: wrap;
    padding-top: rem(48);
    padding-bottom: rem(72);
    border-bottom: 1px solid #dfdfe0;
    @include mq(sp, min, ps) {
      flex-direction: row-reverse;
      align-items: center;
      column-gap: rem(40);
    }
    @include mq(sp) {
      flex-direction: column;
      gap: sprem(24);
      padding-top: sprem(32);
      padding-bottom: sprem(32);
    }
    &:first-child {
      border-top: 1px solid #dfdfe0;
    }
  }
  .post--info {
    width: 100%;
  }
  .post--img {
    @include mq(sp, min, ps) {
      width: 30%;
    }
  }
  .post--txtarea {
    @include mq(sp, min, ps) {
      flex: 1;
    }
  }
  .post--more {
    @include mq(sp, min, ps) {
      position: absolute;
      bottom: rem(32);
      right: 0;
    }
  }
}

/* col3
**************************************** */
.posts-col3 {
  .post {
    border-radius: rem(16);
    box-shadow: 0 0 rem(24) 0 rgba($color: #000, $alpha: 0.1);
    overflow: hidden;
    background-color: var(--bg-wht);
    transition: all 0.5s cubic-bezier(0.5, 0, 0, 1);
    &:hover {
      transform: scale(0.99);
      box-shadow: 0 0 rem(24) 0 rgba($color: #000, $alpha: 0.03);
    }
  }
  .post--img {
    position: relative;
  }
  .post--img,
  .post--img img {
    aspect-ratio: 485/320;
  }
  .post--txtarea {
    padding: rem(40);
  }
  .post--ttl {
    margin-right: rem(32);
    margin-bottom: 0;
    @include mq(sp) {
      margin-right: sprem(16);
    }
  }
  .post--link {
    color: #7a7a81;
  }
}

/* interview
**************************************** */
.posts-interview {
  .post--img,
  .post--img img {
    aspect-ratio: 3/4;
  }
}

/* voice
**************************************** */
.posts-voice {
  .post,
  .post--txtarea {
    display: flex;
    flex-direction: column;
  }
  .post--img {
    flex-shrink: 0;
  }
  .post--txtarea {
    flex: 1;
  }
  .post--info {
    margin-top: auto;
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--clr-wht);
}

/* --- posts-news --- */
.post-type-news .arrow-wh {
  border-color: var(--bg-off_wht);
  background-color: var(--bg-off_wht);
}
.posts-news {
  .post {
    padding-block: 0;
    margin-bottom: rem(40);
    border-radius: rem(16);
    box-shadow: 0 0 rem(24) 0 rgba($color: #000, $alpha: 0.1);
    overflow: hidden;
    background-color: var(--bg-wht);
    transition: all 0.5s cubic-bezier(0.5, 0, 0, 1);
    &:hover {
      transform: scale(0.99);
      box-shadow: 0 0 rem(24) 0 rgba($color: #000, $alpha: 0.03);
    }
  }
  .post,
  .post--txtarea {
    display: flex;
    flex-direction: column;
  }
  .post--date {
    font-weight: 700;
  }
  .post--list {
    padding-left: 0;
    gap: 0 perc(16, 14, em);
    &::before,
    li:not(:last-child)::after {
      display: none;
    }
    a {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-left: perc(26, 14, em);
      &:hover {
        color: #7a7a81;
        filter: brightness(0.8) contrast(3);
      }
    }
    .clr {
      position: absolute;
      top: perc(8, 14, em);
      left: 0;
      width: 1em;
      aspect-ratio: 1/1;
      background-color: #7a7a81;
      border-radius: 50%;
    }
  }
  .post--ttl {
    position: relative;
    padding-right: 0;
    margin-right: 0;
    padding-inline: 2.5rem;
    padding-top: 2.5rem;
    pointer-events: none;
    flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    @include mq(sp) {
      padding-inline: 1.5rem;
      padding-top: 2rem;
    }
  }
  .post--txtarea {
    flex: 1;
    padding: 0;
  }
  .post--img {
    flex-shrink: 0;
    width: 100%;
    height: rem(280);
    margin-right: 0;
    margin-bottom: 0;
    img {
      aspect-ratio: 485 / 320;
    }
    @include mq(sp) {
      height: rem(240);
    }
  }
  .post--info {
    margin-top: 1rem;
    padding-inline: 2.5rem;
    padding-bottom: 2.5rem;
    @include mq(sp) {
      padding-inline: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
  .post--link::before {
    z-index: 1;
  }
  .arrow {
    display: none;
  }
  .post:has(.post--link:hover) {
    .post--ttl {
      color: var(--clr-main);
    }
    .arrow-wh {
      border-color: var(--clr-main);
      background-color: var(--bg-main);
      &::before {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.archive-news .posts-news {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .post {
    flex-direction: column-reverse !important;
    width: 48%;
    @include mq(sp) {
      width: 100%;
      margin-inline: auto;
    }
  }
}
.archive-news .side_column {
  @include mq(med, min, ps) {
    width: rem(550);
  }
}
