@use '../abstracts' as *;

/* Wysiwyg editor
 **************************************** */
.mce-content-body {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  } 
}
.mce-content-body ul,
.mce-content-body ol {
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin-left: perc(40,16,em);
  padding-top: .5em;
  &::marker {
    color: var(--clr-main);
    letter-spacing: perc(3.2,16,em);
  }
}
.mce-content-body ul li {
  list-style: none;
  &::before {
    content: '';
    position: absolute;
    top: perc(17,16,em);
    left: perc(-34,16,em);
    width: perc(10,16,em);
    aspect-ratio: 1/1;
    border: 1px solid var(--clr-main);
    background-color: var(--clr-main);
  }
}
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--bg-wht);
}
.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}
.mce-content-body ol {
  /* list-style-type: decimal-leading-zero; */
}
.mce-content-body ol:not([style*="list"]) li {
  counter-increment: number;
  &::before {
    content: counter(number, decimal-leading-zero)'.';
    position: absolute;
    left: perc(-41,16,em);
    color: var(--clr-main);
    letter-spacing: perc(3.2,16,em);
  }
}
.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.mce-content-body hr {
  border-width: 2px;
  border-color: var(--clr-main);
  margin: 4em 0;
}
.mce-content-body a img {
  transition: var(--transit-default);
}
.mce-content-body a:hover img {
  opacity: .8;
}
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.mce-content-body .aligncenter {
  @include auto-margin;
  margin-bottom: 1em;
}
.mce-content-body img.aligncenter {
  display: block;
}
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.mce-content-body p:not(:last-child) {
  margin-bottom: 2em;
}
.mce-content-body strong {
  font-weight: bold;
}
.mce-content-body blockquote {
  margin: 1em 0;
  padding: rem(16) rem(40);
  border-radius: rem(3);
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: var(--bg-sub);
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 1em;
    height: 1em;
    opacity: .25;
  }
  &::before {
    background-image: url('../images/common/icon-quote_left.svg');
    top: rem(14);
    left: rem(12);
  }
  &::after {
    background-image: url('../images/common/icon-quote_right.svg');
    bottom: rem(14);
    right: rem(12);
  }
}
.mce-content-body blockquote p { margin-bottom: 0; }
.mce-content-body a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.mce-content-body table {
  border: none;
  height: auto !important;
  @include mq(sp) {
    font-size: sprem(14);
    line-height: 170%;
    li::before {
      top: perc(12,14,em);
    }
  }
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #D3D3D3;
  vertical-align: top;
}
.mce-content-body th {
  min-width: rem(120);
  text-align: left;
}
.mce-content-body sup {
  font-size: calc-fz(12);
  color: #555;
}
.bold {
  font-weight: bold;
  color: var(--clr-main);
}
.thin {
  font-weight: 400;
  font-family: var(--font-secondary);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
  border: 1px solid #D3D3D3 !important;
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}

@include mq(sp) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: rem(16);
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: .5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }

  /* table scroll */
  .table-sp-scroll {
    white-space: nowrap;
  }
  .scroll {
    overflow-x: scroll;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
  }
  .scroll table { margin: 0; }
  .scroll--cap {
    text-align: right;
    margin: 1em 0 0 !important;
    opacity: .25;
    font-size: calc-fz(14);
  }
}

/* AddQuicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  font-family: var(--font-jp);
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.editor-ttl {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.editor-ttl2 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.editor-ttl3 {
  font-size: calc-fz(18);
  @include mq(sp) {
    font-size: sprem(16);
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: calc-fz(14);
  opacity: .6;
  line-height: var(--line-height-hdr);
  font-family: var(--font-secondary);
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: '※';
  }
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: calc(100% - rem(3));
  }
}
.table1 th {
  color: var(--clr-main);
  &::before {
    left: 0;
    height: 2px;
    background-color: var(--clr-main);
  }
}
.table1 td::before {
  right: 0;
  height: 1px;
  background-color: #D3D3D3;
}
.table2 {
  background-color: var(--bg-wht);
}
.table2 th {
  background-color: rgba(90,89,142,.1);
}

/* ボックス */
.box {
  padding: rem(32);
  background-color: rgba(90,89,142,.1);
  @include mq(sp) {
    padding: rem(24);
  }
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
  .btn-ctr a {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.lps_sec:nth-child(even),
.lps_sec[style*="background-color"] {
  *:not(.bg-wh) .box {
    background-color: var(--bg-wht);
  }
}

/* リスト */
ul.check--list li {
  &::before,
  &::after {
    content: '';
    position: absolute;
  }
  &::before {
    top: perc(10,16,em);
    left: perc(-40,16,em);
    width: perc(24,16,em);
    aspect-ratio: 1/1;
    background-color: var(--clr-main);
    border-radius: 50%;
  }
  &::after {
    top: perc(17,16,em);
    left: perc(-33,16,em);
    width: perc(10,16,em);
    height: perc(7,16,em);
    border-left: 2px solid var(--clr-wht);
    border-bottom: 2px solid var(--clr-wht);
    transform: rotate(-45deg);
  }
}

@include mq(sp) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
}

/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
}
.mce-content-body.wp-editor {
  font-size: 16px;
  padding: rem(24) !important;
  cursor: inherit;
}

@include mq(sp) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}