@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  z-index: z(header);
  transition: var(--transit-default);
  font-weight: 700;
  @include mq(med, min, ps) {
    border-bottom: 1px solid rgba($color: $clr-wht, $alpha: 0.2);
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    pointer-events: none;
    &.active {
      background-color: var(--bg-wht);
      pointer-events: auto;
      .header--logo a {
        filter: none !important;
      }
    }
  }
  & > .inner {
    width: vw(1792);
    max-width: vw(1792);
    height: 100%;
    @include mq(med) {
      width: spvw(342);
      max-width: spvw(342);
    }
  }
}
.subpage .header,
.header-sm {
  @include mq(med, min, ps) {
    background-color: var(--bg-off_wht);
  }
}
.page-contact-simple .header {
  position: absolute;
}
.header-sm {
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}

/* --- logo -- */
.header--logo a,
.footer--logo a {
  display: block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo {
  @include mq(med) {
    pointer-events: auto;
  }
}
.home .header:not(.header-sm) .header--logo a {
  filter: brightness(0) invert(1);
}
.header--logo img {
  width: auto !important;
  max-height: rem(54);
  @include mq(med) {
    max-height: sprem(54);
  }
}

/* gnav
********************************************** */
.gnav,
.gnav ul,
.gnav li,
.gnav a {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
  }
  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - sprem($header-hgt-sp));
    margin: 0;
    padding: sprem(18) sprem(40) sprem(32);
    border-top: 1px solid #dfdfe0;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--bg-wht);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
  }
  .copyright {
    color: #ccc;
    @include mq(med, min, ps) {
      display: none;
    }
  }
  .pbl {
    display: none;
  }
}
.active .gnav {
  opacity: 1;
  pointer-events: auto;
}
.gnav a {
  @include center-flex;
  line-height: 160%; /* 28.8px */
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu {
  @include mq(med) {
    padding-bottom: sprem(4);
    margin-bottom: sprem(32);
    border-bottom: 1px solid #dfdfe0;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
  }
}
.gnav--link {
  padding: 0 rem(16);
  @include mq(med) {
    justify-content: flex-start !important;
    padding: sprem(14) sprem(20) sprem(14) 0;
  }
}
.home .header:not(.header-sm) .gnav--link {
  @include mq(med, min, ps) {
    color: var(--clr-wht);
  }
}
.gnav--menu li:not(.menu-item-has-children) a::after {
  @include mq(med) {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: sprem(8);
    margin: auto;
    @include sprect(7);
    border-top: 1px solid var(--clr-main);
    border-right: 1px solid var(--clr-main);
    transform: rotate(45deg);
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    color: var(--clr-main);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  z-index: 100;
  @include mq(med, min, ps) {
    position: absolute;
    top: calc(100% - rem(16));
    left: 0;
    transition: var(--transit-default);
    opacity: 0;
    pointer-events: none;
    background-color: var(--bg-wht);
    padding: rem(27) rem(32);
    border-radius: rem(16);
    white-space: nowrap;
    box-shadow: 0 0 rem(24) 0 rgba($color: #000, $alpha: 0.1);
    backdrop-filter: blur(rem(4));
    &::before {
      content: "";
      position: absolute;
      top: rem(-11);
      left: rem(32);
      @include rect(24, 15);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background-color: var(--bg-wht);
    }
  }
  @include mq(med) {
    display: none;
  }
  .head_sub_nav--wrap {
    position: relative;
    box-shadow: none;
    border-radius: inherit;
    padding-inline: 0;
    padding-block: 0;
    padding-left: rem(16);
    .head_sub_nav a::before {
      border-bottom: 2px solid var(--bg-main);
    }
  }
}
@include mq(sp) {
  .head_sub_nav--wrap .head_sub_nav--wrap + .gnav_sub_toggle {
    margin-top: -12px;
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav a {
  position: relative;
  display: block;
  padding: rem(5) 0 rem(5) rem(24);
  font-size: calc-fz(14);
  letter-spacing: perc(0.42, 14, em);
  color: var(--clr-body) !important;
  @include mq(med, min, ps) {
    background-color: var(--bg-wht);
    &:hover {
      color: var(--clr-main) !important;
    }
  }
  @include mq(med) {
    font-size: sprem(12);
    padding: sprem(5) 0 sprem(5) sprem(24);
    &::after {
      display: none;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: rem(14);
    left: 0;
    width: rem(16);
    height: 2px;
    border-bottom: 2px solid #d9d9d9;
    @include mq(med) {
      top: sprem(12);
      width: sprem(16);
    }
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: rem(-20);
    @include sprect(54);
    transition: var(--transit-default);
    color: var(--clr-main);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      margin: auto;
      width: rem(12);
      height: 1px;
      border-bottom: 1px solid;
      transition: var(--transit-default);
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
      &::after {
        opacity: 0;
      }
    }
  }
}

/* ---btn --- */
.gnav_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    @include sprect(48);
    border: 2px solid #dfdfe0;
    background-color: var(--bg-off_wht);
    border-radius: 50%;
    z-index: 9999;
    transition: var(--transit-default);
    pointer-events: auto;
    cursor: pointer;
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(18, 12);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #9599a3;
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(5)) rotate(-40deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(sprem(-5)) rotate(40deg);
    }
  }
}
.gnav--cta {
  @include mq(med, min, ps) {
    margin-left: rem(16);
  }
  @include mq(med) {
    margin-bottom: sprem(32);
  }
}
.gnav--contact {
  @include mq(med, min, ps) {
    height: rem(45) !important;
  }
}
.gnav--contact a {
  padding: rem(8) rem(28);
  @include mq(med, min, ps) {
    min-width: inherit !important;
  }
  @include mq(med) {
    padding: sprem(16) sprem(28);
  }
  &::after {
    display: none !important;
  }
}
.gnav--menu2 {
  font-size: sprem(12);
  margin-left: sprem(-7.5);
  white-space: nowrap;
  @include mq(med, min, ps) {
    display: none;
  }
}
.gnav--menu2 a {
  padding: 0 sprem(7.5);
  color: #7a7a81;
}

/* header 01
********************************************** */
.header-01 {
  @include mq(med, min, ps) {
    box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    .gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
    .gnav--menu > li.current-menu-item > .gnav--link {
      color: var(--clr-main);
      background-color: var(--bg-off_wht);
    }
  }
  .head_sub_nav--wrap {
    @include mq(sp) {
      padding-inline: sprem(14);
      background-color: var(--bg-wht);
    }
  }
  .head_sub_nav li {
    @include mq(med, min, ps) {
      margin-block: rem(8);
    }
  }
  .head_sub_nav a {
    line-height: 1.5;
    @include mq(med, min, ps) {
      box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    }
  }
}

/* header 02
********************************************** */
.header-02 {
  @include mq(med, min, ps) {
    padding-right: rem(32);
    box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    .gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
    .gnav--menu > li.current-menu-item > .gnav--link {
      color: var(--clr-main);
      background-color: var(--bg-off_wht);
    }
  }
  .head_sub_nav--wrap {
    left: 50%;
    translate: -50% 0;
    min-width: rem(248);
  }
  .head_sub_nav {
    position: relative;
    min-width: rem(248);
    background-color: var(--bg-wht);
    @include mq(med, min, ps) {
      padding: rem(20);
      margin-top: rem(20);
      border-radius: rem(8);
      box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    }
    @include mq(sp) {
      padding: sprem(10) sprem(16);
    }
    @include mq(med, min, ps) {
      &::before {
        content: "";
        position: absolute;
        top: rem(-12);
        left: calc(50% - rem(24) / 2);
        width: rem(24);
        height: rem(12);
        background-color: var(--bg-wht);
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
      }
    }
  }
  .head_sub_nav a {
    position: relative;
    padding: rem(10);
    text-align: left;
    line-height: 1.5;
    @include mq(sp) {
      padding: 1em 1.5em;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
      @include rect(8);
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
  }
  .gnav--contact a {
    gap: rem(8);
    @include mq(med, min, ps) {
      height: 130%;
      border-radius: 0 0 rem(24) rem(24);
      flex-direction: column;
    }
  }
  .icon-mail {
    @include mq(med, min, ps) {
      width: rem(32);
    }
  }
}

/* マウスストーカー
**************************************** */
.cursor,
.stalker {
  position: fixed;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  pointer-events: none;
  z-index: z(header);
  mix-blend-mode: exclusion;
}
.cursor {
  @include center-flex;
  @include rect(8);
  background-color: var(--bg-wht);
  margin-top: rem(-4);
  margin-left: rem(-4);
  .font-en {
    font-weight: 700;
    text-align: center;
    color: var(--clr-wht);
    white-space: nowrap;
    transition: var(--transit-default);
    opacity: 0;
  }
  &.active {
    background-color: transparent;
    .font-en {
      opacity: 1;
    }
  }
}
.stalker {
  @include rect(40);
  border: 1px solid rgba($color: $clr-wht, $alpha: 0.5);
  margin-top: rem(-20);
  margin-left: rem(-20);
  transition: var(--transit-default);
  &.active {
    @include rect(240);
    margin-top: rem(-122);
    margin-left: rem(-122);
  }
}
