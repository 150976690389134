@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  & > .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: rem(56);
    padding-block: rem(246) rem(128);
    @include mq(sp) {
      gap: sprem(32);
      padding-block: sprem(180) sprem(88);
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      @include rect(120,4);
      background-color: var(--bg-main);
      @include mq(sp) {
        @include sprect(60,4);
      }
    }
  }
  .post--list * {
    color: var(--clr-wht);
  }
  .ttl-label {
    margin-bottom: rem(-26);
    @include mq(sp) {
      margin-bottom: sprem(-16);
    }
  }
}
.page_ttl--bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba($color: #7A7A81, $alpha: .4);
    pointer-events: none;
  }
}
.page_ttl-jp {
  font-size: calc-fz(24);
  letter-spacing: perc(.72,24,em);
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.page_ttl-en {
  display: block;
  font-size: rem(152);
  font-weight: 200;
  line-height: 86%; /* 130.72px */
  letter-spacing: perc(-1.52,152,em);
  @include mq(sp) {
    font-size: sprem(56);
  }
}
.page_ttl-catch {
  margin-bottom: 0 !important;
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  .breadcrumbs a {
    color: var(--clr-wht);
    &:hover {
      color: var(--clr-main);
    }
  }
  .breadcrumbs span span:not(:last-child)::after {
    border-color: var(--clr-wht);
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  position: relative;
  &.font-jp {
    letter-spacing: .1em;
  }
  &.font-en {
    font-weight: 400;
  }
}
.ttl-01 {
  font-size: rem(56);
  line-height: 110%; /* 74.8px */
  color: var(--clr-main);
  @include mq(sp) {
    font-size: sprem(32);
  }
  h2 {
    line-height: 110%;
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(88,68,em);
  }
  &.font-en {
    font-size: rem(68);
    @include mq(sp) {
      font-size: sprem(40);
    }
    .txt {
      letter-spacing: 0;
    }
  }
  &.txt-wh .ttl-01-sub {
    color: var(--clr-wht);
    &::after {
      background-color: var(--bg-wht);
    }
  }
  .btn-more {
    @include mq(sp, min, ps) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.ttl-01-sub {
  position: relative;
  display: block;
  color: #7A7A81;
  font-size: calc-fz(18);
  line-height: 160%; /* 28.8px */
  letter-spacing: perc(.54,18,em);
  padding-left: perc(28,18,em);
  margin-top: perc(40,18,em);
  @include mq(sp) {
    font-size: sprem(16);
    margin-top: perc(24,16,em);
  }
  &.font-en {
    font-weight: 700;
  }
  &::before {
    content: '';
    position: absolute;
    top: perc(5,18,em);
    left: 0;
    width: perc(14,18,em);
    aspect-ratio: 1/1;
    background-color: var(--bg-main);
    border-radius: 50%;
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(48,16,em);
  }
}
.ttl-02 {
  font-size: rem(40);
  line-height: 160%; /* 64px */
  @include mq(sp) {
    font-size: sprem(24);
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(56,40,em);
  }
}
.ttl-03 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(24);
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(18,24,em);
  }
}
.ttl-label {
  width: fit-content;
  min-width: min(rem(204),100%);
  border-radius: rem(8);
  padding: 0 perc(24,32,em);
  background-color: var(--bg-off_wht);
  line-height: 170%; /* 54.4px */
  text-align: center;
  letter-spacing: 0;
  @include mq(sp) {
    min-width: min(rem(160),100%);
  }
}
.ttl-label-wh {
  background-color: var(--bg-wht);
}