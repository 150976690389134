@use '../abstracts' as *;

/* lps_parts--button
********************************************** */
.lps_parts--button > .inner {
  @include mq(sp, min, ps) {
    width: fit-content;
    min-width: min(rem(960),90%);
    @include auto-margin;
  }
}